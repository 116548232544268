<template>
  <loading v-if="repaint" />
  <line-chart v-else ref="chart" :chart-data="chartData" :options="options" />
</template>

<script>
export default {
  name: "EarningLast30DaysChart",
  inject: ["affAccountProviderData"],
  data() {
    return {
      yAxisMax: null,
      yAxisMin: null,
      repaint: false
    };
  },
  computed: {
    affAccountData() {
      return this.affAccountProviderData().affAccountData;
    },
    last30Days() {
      const days = [];
      for (let index = 0; index < 30; index++) {
        days.push(this.$moment().subtract(index, "days"));
      }
      return days.reverse();
    },
    chartData() {
      return {
        labels: this.$_.map(this.last30Days, d => {
          return d.format("MMM Do");
        }),
        datasets: this.datasets
      };
    },
    colors() {
      return {
        usd: {
          borderColor: "rgba(0, 198, 206, 1)",
          backgroundColor: "rgba(0, 198, 206, 0.15)"
        }
      };
    },
    datasets() {
      return ["usd"].map(currency => {
        return this.getDataset(currency);
      });
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                max: Math.round(this.yAxisMax),
                beginAtZero: true
              }
            }
          ]
        }
      };
    }
  },
  watch: {
    "affAccountData.stats": {
      deep: true,
      immediate: true,
      handler() {
        this.resetMaxAxis();
        this.repaint = true;
        this.$nextTick(() => {
          this.repaint = false;
        });
      }
    }
  },
  methods: {
    getDataset(currency) {
      return {
        label: currency.toUpperCase(),
        borderWidth: 2,
        borderColor: this.colors[currency]
          ? this.colors[currency].borderColor
          : "rgba(0, 198, 206, 1)",
        backgroundColor: this.colors[currency]
          ? this.colors[currency].backgroundColor
          : "rgba(0, 198, 206, 0.15)",
        data: this.getData()
      };
    },
    getData() {
      const earningsByDay = this.$_.get(
        this.affAccountData,
        `stats.earningsByDay`,
        {}
      );
      return this.$_.map(this.last30Days, d =>
        this.$_.get(earningsByDay, d.format("YYYYMMDD"), 0)
      );
    },
    resetMaxAxis() {
      this.yAxisMin = this.yAxisMax = 0;
      this.datasets.forEach(dataset => {
        this.$_.forEach(dataset.data, data => {
          if (this.yAxisMax < data) {
            this.yAxisMax = data;
          }
          if (this.yAxisMin > data) {
            this.yAxisMin = data;
          }
        });
      });

      // plus 85% on top of max
      this.yAxisMax = (((1 * this.yAxisMax) | 0) + this.yAxisMax) | 0;
      this.yAxisMax = this.yAxisMax < 10 ? 10 : this.yAxisMax;
    }
  }
};
</script>
